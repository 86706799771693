<template>
    <CRow>
        <CCol sm="3">
            <CCard>
                <CCardHeader>
                    <strong> User Search </strong> <small></small>
                    <div class="card-header-actions"></div>
                </CCardHeader>
                <CCardHeader>
                    <CInput v-c-tooltip.hover.click="`Start typing anything that might describe one user.`"
                            @input="userLookup()" v-model="search_criteria" placeholder="Search">
                        <template #prepend>
                            <CButton @click="userLookup()" size="sm" color="primary">
                                <CIcon name="cil-magnifying-glass"/>
                                Search
                            </CButton>
                        </template>
                    </CInput>
                </CCardHeader>
                <div v-for="user in founded_users">

                    <h6 style="margin-left: 20px; margin-top: 5px; text-underline-position: below"
                        @click="selectUser(user)"
                    >
                        📧 {{user.usr_email}} <br><br> 👤 {{user.usr_firstname}} {{user.usr_lastname}} <br><br> 📶
                        {{user.wlan_ssid}}
                    </h6>
                    <hr>
                </div>


                <!--                </CListGroupItem>-->
            </CCard>

        </CCol>
        <CCol sm="4">

            <CCard>
                <CCardHeader>
                    User Residence Info
                </CCardHeader>
                <CCardBody>
                    <CForm v-if="selected_user">

                        <CInput
                                type="text"
                                label="Property Name"
                                horizontal
                                :value.sync="selected_user.pro_shortname"
                                plaintext
                        ></CInput>

                        <CInput
                                type="text"
                                label="Building Name"
                                horizontal
                                :value.sync="selected_user.bul_name"
                                plaintext
                        ></CInput>

                        <CInput
                                type="text"
                                label="Unit Name"
                                horizontal
                                :value.sync="selected_user.uni_name"
                                plaintext
                        ></CInput>
                    </CForm>
                </CCardBody>

            </CCard>

            <CCard v-if="selected_unit">
                <CCardHeader>
                    Emergency Wlan Info
                </CCardHeader>
                <CCardBody>
                    <CForm v-if="selected_unit">

                        <CInput
                                type="text"
                                label="Property Name"
                                horizontal
                                :value.sync="selected_unit.pro_shortname"
                                plaintext
                        ></CInput>

                        <CInput
                                type="text"
                                label="Building Name"
                                horizontal
                                :value.sync="selected_unit.bul_name"
                                plaintext
                        ></CInput>

                        <CInput
                                type="text"
                                label="Unit Name"
                                horizontal
                                :value.sync="selected_unit.uni_name"
                                plaintext
                        ></CInput>
                    </CForm>
                </CCardBody>

            </CCard>
            <CCard v-if="selected_user && selected_unit">
                <CCardBody>

                    <CButton v-if="!hostWlanFlag" color="success" @click="hostWlan()">Host Wlan</CButton>
                    <CButton v-if="hostWlanFlag" disabled color="success" @click="hostWlan()">Host Wlan</CButton>

                </CCardBody>
            </CCard>
            <CCard>
                <CCardHeader>
                    <strong>&nbsp;Wlan hosted units</strong>
                </CCardHeader>
                <CCardBody v-if="selected_user && !wlanMembershipFlag" >
                    <CListGroup>

                        <template v-for="member in membership">

                            <CListGroupItem href="#"
                                            class="flex-column align-items-start">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{{member.ap_info.uni_name}} {{member.ap_status}}</h5>
                                </div>
                                <p class="mb-1">
                                    WLAN Id: {{member.id}} <br>
                                    Zone Id: {{member.zoneId}} <br>
                                    WG Name: {{member.name}} <br>

                                    Building Name: {{member.ap_info.bul_name}} <br>
                                    Property Name: {{member.ap_info.pro_shortname}}

                                </p>
                            </CListGroupItem>
                        </template>
                    </CListGroup>

                </CCardBody>

                <div v-if="wlanMembershipFlag">

                    <div class="lds-ripple"><div></div><div></div><div></div></div>

                </div>
            </CCard>

        </CCol>
        <CCol sm="4">

            <CCard>
                <CCardHeader>
                    <strong> Search unit by criteria </strong> <small></small>
                    <div class="card-header-actions"></div>
                </CCardHeader>
                <CCardHeader>
                    <CInput v-c-tooltip.hover.click="`Query might be ${queries_units} `"
                            @input="unitLookup()" v-model="search_criteria_unit" placeholder="query> value">
                        <template #prepend>
                            <CButton @click="unitLookup()" size="sm" color="primary">
                                <CIcon name="cil-magnifying-glass"/>
                                Search
                            </CButton>
                        </template>
                    </CInput>
                </CCardHeader>

                <CListGroupItem @click="selectUnit(unit)" v-for="unit in founded_units" href="#"
                                class="flex-column align-items-start">
                    <div v-c-tooltip.hover.click="'Click to get unit details'"
                         class="d-flex w-100 justify-content-between">
                    </div>

                    <h5 class="mb-1"> Residence Info </h5>

                    <ul>
                        <li>
                            Property: {{unit.pro_shortname}}

                        </li>
                        <li>
                            Building: {{unit.bul_name}}
                        </li>
                        <li>
                            Unit name: {{unit.uni_name}}
                        </li>
                    </ul>


                    <h5 class="mb-1"> AP Info </h5>

                    <ul>
                        <li>
                            AP MAC: {{unit.rap_mac}}
                        </li>
                        <li>
                            AP Name: {{unit.rap_name}}
                        </li>
                    </ul>


                </CListGroupItem>
            </CCard>

        </CCol>
    </CRow>
</template>

<script>

    import axios from "axios";

    export default {
        name: 'EmergencyWlan',
        data() {
            return {
                queries: ['usr_id', 'usr_firstname', 'usr_lastname', 'usr_email',
                    'usr_phonenumber',
                    'wlan_ssid', 'uni_name', 'rap_mac', 'rvlan_vlanid'],
                queries_units: ['uni_name', 'rap_mac', 'rvlan_vlanid'],
                search_criteria: "",
                search_criteria_unit: "",
                selected_user: null,
                selected_unit: null,
                founded_units: null,
                founded_users: null,
                membership: null,
                wlanMembershipFlag: false,
                hostWlanFlag: false
            }
        },
        methods: {
            getMembership() {
                this.wlanMembershipFlag = true;
                axios.get(this.$baseURL + '/ruckus/wl/membership?wl_id=' + this.selected_user.rwlan_wlanid +
                    '&rctl_id=' + this.selected_user.rctl_id + '&zone_rid=' + this.selected_user.zone_rid,
                    this.ap)
                    .then((res) => {
                        this.membership = res.data;
                        this.$toast.success("Successfully fetched WLAN membership");
                        this.wlanMembershipFlag = false;
                    }).catch((error) => {
                    this.wlanMembershipFlag=false;
                    this.$toast.error("Failed to sync WLAN membership " + error);
                    console.log(error);
                })
            },
            hostWlan() {
                this.hostWlanFlag = true;

                axios.post(this.$baseURL + '/ruckus/wl/wg_add', {
                    selected_item: this.selected_unit,
                    data: this.selected_user
                })
                    .then((res) => {
                        res;
                        this.hostWlanFlag = false;
                        this.$toast.success(`Successfully hosted.`)
                        this.getMembership();

                    }).catch((error) => {
                    this.$toast.error(error.response.data.detail);
                    this.hostWlanFlag = false;
                })
            },
            userLookup() {
                axios.get(this.$baseURL + '/users/db/deep_search?search=' + this.search_criteria)
                    .then((res) => {
                        this.founded_users = res.data;
                    }).catch(
                    (error) => {
                        console.log(error);
                    }
                )
            },
            selectUser(item) {

                this.selected_user = item;
                this.getMembership()
            },

            unitLookup() {

                if (!this.search_criteria_unit.includes('>')) {
                    return
                }
                var query = this.search_criteria_unit.split('>')[0];
                var value = "";

                if (this.queries_units.includes(query)) {
                    value = this.search_criteria_unit.split('> ')[1];
                }
                if (!value) {
                    return
                }

                axios.get(this.$baseURL + '/ruckus/ap/db/search?param=' + query + "&value=" + value)
                    .then((res) => {
                        this.founded_units = res.data;
                    }).catch(
                    (error) => {
                        console.log(error);
                    }
                )
            },
            selectUnit(item) {

                this.selected_unit = item;
            },
        },
        mounted() {

        },
        computed: {}
    }
</script>


<style scoped>
    .lds-facebook {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-facebook div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #4673e7;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .lds-facebook div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }

    .lds-facebook div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }

    .lds-facebook div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }

    @keyframes lds-facebook {
        0% {
            top: 8px;
            height: 64px;
        }
        50%, 100% {
            top: 24px;
            height: 32px;
        }
    }

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-ripple div {
        position: absolute;
        border: 4px solid #4673e7;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }

</style>